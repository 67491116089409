<template>
  <div>
    <c-table
      ref="table"
      title="순회점검 미개선 목록"
      :columns="grid.columns"
      :data="grid.data" 
      :merge="grid.merge"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='actionCompleteRequestDate'">
          <span :style="props.row.approvalStatusCd === 'ASC9999999' ? 'text-decoration: line-through' : ''">
            {{props.row.actionCompleteRequestDate}}
            <!-- 지연 -->
            <q-badge v-if="props.row.completeDateOver > 0
              && props.row.ibmStepCd !== 'IS00000001' && props.row.approvalStatusCd !== 'ASC9999999'" color="negative" :label="$label('LBL0001414')" class="blinking" />
          </span>
          <span v-if="props.row.approvalStatusCd === 'ASC9999999'">
            👉 {{props.row.targetDate}}
          </span>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'assessRiskRegisterStatus',
  props: {
    searchParam: {
      type: Object,
      default: () => ({
        plantCd: null,
        assessmentDates: [],
        deptCd: null,
        ibmStepCd: null,
      }),
    },
  },
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'ramRiskAssessmentPlanId' },
          { index: 1, colName: 'processCd' },
          { index: 2, colName: 'processCd' },
          { index: 3, colName: 'assessItem' },
        ],
        data: [],
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            label: '점검부서',
            align: 'center',
            style: 'width:110px',
            sortable: false,
          },
          {
            name: 'patrolDate',
            field: 'patrolDate',
            label: '순회일',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'patrolContents',
            field: 'patrolContents',
            label: '유해위험요인',
            align: 'left',
            style: 'width:200px',
          },
          {
            name: 'impr',
            field: 'impr',
            label: '개선',
            child: [
              {
                name: 'plantName',
                field: 'plantName',
                label: '사업장',
                align: 'center',
                style: 'width:100px',
                sortable: false,
              },
              {
                name: 'ibmStepName',
                field: 'ibmStepName',
                label: '개선 진행상태',
                align: 'center',
                style: 'width:110px',
                sortable: false,
                type: 'link'
              },
              {
                name: 'request',
                field: 'request',
                label: '요청',
                child: [
                  {
                    name: 'actionCompleteRequestDate',
                    field: 'actionCompleteRequestDate',
                    label: '조치완료요청일',
                    align: 'center',
                    style: 'width:110px',
                    sortable: false,
                    type: 'custom',
                  },
                  {
                    name: 'improveRequestDeptUserName',
                    field: 'improveRequestDeptUserName',
                    label: '개선요청자',
                    align: 'center',
                    style: 'width:140px',
                    sortable: false
                  },
                  {
                    name: 'improveRequestContents',
                    field: 'improveRequestContents',
                    label: '개선요청내용',
                    align: 'left',
                    style: 'width:180px',
                    sortable: false,
                    type: 'html',
                  },
                ]
              },
              {
                name: 'action',
                field: 'action',
                label: '조치',
                child: [
                  {
                    name: 'actionDeptName',
                    field: 'actionDeptName',
                    label: '조치부서',
                    align: 'center',
                    style: 'width:140px',
                    sortable: false
                  },
                ]
              },
            ]
          },
        ],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    requestContentsCols() {
      return ['riskHazardQuestionName'];
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.ram.riskRegister.patrol.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.popupOptions.title = 'LBL0001415'; // 개선 상세
      this.popupOptions.isFull = true;
      this.popupOptions.param = {
        sopImprovementId: row ? row.sopImprovementId : '',
        ibmTaskTypeCd: row ? row.ibmTaskTypeCd : 'ITT0000180',
        ibmTaskUnderTypeCd: row ? row.ibmTaskUnderTypeCd : 'ITTU000240',
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>