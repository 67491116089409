var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title: "순회점검 미개선 목록",
            columns: _vm.grid.columns,
            data: _vm.grid.data,
            merge: _vm.grid.merge,
          },
          on: { linkClick: _vm.linkClick },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props, col }) {
                return [
                  col.name === "actionCompleteRequestDate"
                    ? [
                        _c(
                          "span",
                          {
                            style:
                              props.row.approvalStatusCd === "ASC9999999"
                                ? "text-decoration: line-through"
                                : "",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(props.row.actionCompleteRequestDate) +
                                " "
                            ),
                            props.row.completeDateOver > 0 &&
                            props.row.ibmStepCd !== "IS00000001" &&
                            props.row.approvalStatusCd !== "ASC9999999"
                              ? _c("q-badge", {
                                  staticClass: "blinking",
                                  attrs: {
                                    color: "negative",
                                    label: _vm.$label("LBL0001414"),
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        props.row.approvalStatusCd === "ASC9999999"
                          ? _c("span", [
                              _vm._v(
                                " 👉 " + _vm._s(props.row.targetDate) + " "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _c("c-btn", {
                    attrs: { label: "LBLSEARCH", icon: "search" },
                    on: { btnClicked: _vm.getList },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }